"use client";

import { Fragment, useRef, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { useSession } from "next-auth/react";

import Button from "@/components/atoms/Button";
import Icon from "@/components/atoms/Icon";
import { Logo } from "@/components/atoms/Logo";
import { LanguageSwitcher } from "@/components/molecules/LanguageSwitcher";
import { MobileMenu } from "@/components/molecules/MobileMenu";
import { SubNavGroup } from "@/components/molecules/SubNavGroup";
import { ToggleAudience } from "@/components/molecules/ToggleAudience";
import { MenuFieldsFragment } from "@/graphql/sdk/__generated__";
import { cn } from "@/helpers/className";
import logoUrl from "@/assets/images/logo-vivaldis.svg";
import { useScrollDirection } from "@/lib/hooks/useScrollDirection";
import { useTargetAudience } from "@/store/targetAudience";
import { AllowedLanguages, AvailableTargetAudience } from "@/types/generic";
import {AccountNavBtn} from "@/components/organisms/AccountNavBtn";
import {savedJobsPaths} from "routes";

interface HeaderProps {
  i18n: {
    employee: string;
    employer: string;
    my_vivaldis: string;
    open_menu: string;
  };
  locale: AllowedLanguages;
  mainMenuEmployee?: MenuFieldsFragment;
  mainMenuEmployer?: MenuFieldsFragment;
  secondaryMenuEmployee?: MenuFieldsFragment;
  secondaryMenuEmployer?: MenuFieldsFragment;
}

export const Header = ({
  i18n,
  locale,
  mainMenuEmployee,
  secondaryMenuEmployee,
  mainMenuEmployer,
  secondaryMenuEmployer,
}: HeaderProps) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { targetAudience } = useTargetAudience();
  const headerRef = useRef<HTMLElement>(null);

  const mainMenu =
    targetAudience === AvailableTargetAudience.EMPLOYEE
      ? mainMenuEmployee
      : mainMenuEmployer;
  const secondaryMenu =
    targetAudience === AvailableTargetAudience.EMPLOYEE
      ? secondaryMenuEmployee
      : secondaryMenuEmployer;

  const pathname = usePathname();

  const { scrollDirection, scrollY } = useScrollDirection();

  const headerHiddenClass = "xl:!top-[-100%]";
  const headerNormalClass = "xl:top-0";
  const headerCondensedClass = "xl:top-0";

  const { data: session } = useSession();

  return (
    <header
      className={cn(
        "z-50 bg-white transition-all duration-500 xl:fixed xl:inset-x-0 xl:bg-transparent",
        scrollY > 200 ? headerCondensedClass : headerNormalClass,
        scrollDirection === "down" && scrollY >= 200 ? headerHiddenClass : "",
      )}
      ref={headerRef}
    >
      <div>
        {/* Desktop header */}
        <div
          className={cn(
            "header hidden transition duration-200 xl:block",
            scrollDirection === "down" ? "" : "shadow-sm",
          )}
        >
          {/* Secondary navigation (Top menu) */}
          <nav className="border-bottom hidden bg-grey-100 px-6 py-2 md:block">
            <div className="container flex items-center justify-between">
              {/* Left */}
              <ToggleAudience
                i18n={{
                  employee: i18n.employee,
                  employer: i18n.employer,
                }}
                locale={locale}
              />

              {/* Right */}
              <div className="flex items-center gap-6">
                {secondaryMenu?.items?.map((item) => (
                  <Link
                    key={item.id}
                    href={item.url}
                    className={cn(
                      "inline-flex items-center text-sm transition-colors",
                      pathname === item.url
                        ? "text-black"
                        : "text-black-400 hover:text-green",
                    )}
                  >
                    {item.title}
                  </Link>
                ))}

                <div className="divider-l pl-6">
                  <LanguageSwitcher locale={locale} />
                </div>
              </div>
            </div>
          </nav>

          {/* Main navigation */}
          <nav className="bg-white px-8 py-6">
            <div className="container flex items-center justify-between">
              {/* Logo */}
              <div className="mr-6 flex">
                <Link href={`/${locale}`}>
                  <Logo />
                </Link>
              </div>

              {/* Main nav desktop */}
              <div className="ml-auto flex items-center space-x-8 lg:mr-10 2xl:space-x-12">
                {mainMenu?.items?.map((item) => (
                  <Fragment key={item.id}>
                    {item.children?.length ? (
                      <SubNavGroup
                        itemTitle={item.title}
                        subNav={item.children}
                        itemClassName={cn(
                          "inline-flex items-center px-1 pt-1 font-medium transition-colors 2xl:text-lg",
                          pathname === item.url
                            ? "text-black"
                            : "text-black-400 hover:text-green",
                        )}
                        itemUrl={item.url}
                        className="relative"
                        linkClassName="whitespace-nowrap hover:text-green transition-colors"
                        subNavClassName="absolute top-full -left-6 bg-white border rounded-lg shadow-sm p-4 z-10"
                      />
                    ) : (
                      <Link
                        href={item.url}
                        className={cn(
                          "inline-flex items-center px-1 pt-1 font-medium transition-colors 2xl:text-lg",
                          pathname === item.url
                            ? "text-black"
                            : "text-black-400 hover:text-green",
                        )}
                      >
                        {item.title}
                      </Link>
                    )}
                  </Fragment>
                ))}
              </div>

              {/* Main navigation - right */}
              <div className="flex items-center gap-2 md:gap-4">
                {session && (
                  <Link href={`/${locale}/myvivaldis/${savedJobsPaths[locale]}`}>
                    <Icon
                      name="love"
                      className="h-6 w-6 transition-colors hover:text-yellow"
                    />
                  </Link>
                )}

                <Link href="#" className={"md:hidden"}>
                  <UserCircleIcon className="h-6 w-6" aria-hidden="true" />
                </Link>

                {session ? (
                  <AccountNavBtn session={session} locale={locale}/>
                ) : (
                  <Button
                    className="btn-lg ml-6"
                    arrow={false}
                    as="link"
                    href={`/api/auth/signin?callbackUrl=/${locale}/myvivaldis/profile`}
                  >
                    {i18n.my_vivaldis}
                  </Button>
                )}
              </div>
            </div>
          </nav>
        </div>

        {/* Mobile header */}
        <div className="container flex items-center justify-between py-2 xl:hidden">
          <Link href={`/${locale}`}>
            <span className="sr-only">Vivaldis Interim</span>
            <Image
              src={logoUrl as string}
              width={107}
              height={56}
              alt="Vivaldis Interim logo"
            />
          </Link>

          <div className="flex items-center gap-3">
            {session ? (
              <AccountNavBtn session={session} locale={locale}/>
            ) : (
              <Link
                href={`/api/auth/signin?callbackUrl=/${locale}/myvivaldis/profile`}
                className="text-grey hover:text-black"
                title={i18n.my_vivaldis}
              >
                <Icon name="profile" className="h-8 w-8" />
              </Link>
            )}

            <button
              type="button"
              className="btn inline-flex h-12 w-12 items-center justify-center rounded-md bg-yellow p-2.5 text-black-400"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">{i18n.open_menu}</span>

              <span className="flex w-4 flex-col gap-y-px">
                {Array.from({ length: 3 }, (_, i) => (
                  <svg
                    width="17"
                    height="3"
                    viewBox="0 0 17 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    key={i}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.3335 1.5C16.3335 2.05228 15.8858 2.5 15.3335 2.5H1.3335C0.781211 2.5 0.333496 2.05228 0.333496 1.5C0.333496 0.947715 0.781211 0.5 1.3335 0.5H15.3335C15.8858 0.5 16.3335 0.947715 16.3335 1.5Z"
                      fill="#5D5F61"
                    />
                  </svg>
                ))}
              </span>
            </button>
          </div>
        </div>
      </div>

      <MobileMenu
        mobileMenuOpen={mobileMenuOpen}
        mainMenu={mainMenu}
        secondaryMenu={secondaryMenu}
        setMobileMenuOpen={setMobileMenuOpen}
        locale={locale}
      />
    </header>
  );
};
